import { cn } from "@nephroflow/design-system/styling/utils";
import * as React from "react";

import { isMessageDescriptor, MessageDescriptor, useIntl } from "~/intl";

import { InputVariantProps, inputVariants } from "./constants";
import { CommonInputProps } from "./types";

type BaseProps = CommonInputProps<string | null>;
type CustomProps = {
  placeholder?: MessageDescriptor | string;
} & Omit<InputVariantProps, "disabled">;
type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type TextInputProps = BaseProps & CustomProps & Omit<InputProps, keyof BaseProps | keyof CustomProps>;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    id,
    name,
    defaultValue,
    value,
    onChange,
    onBlur,
    placeholder,
    className,
    size,
    disabled = false,
    autoFocus = false,
    ...inputProps
  } = props;
  const { formatMessage } = useIntl();
  const isControlled = !!onChange;

  return (
    <input
      ref={ref}
      placeholder={isMessageDescriptor(placeholder) ? formatMessage(placeholder) : placeholder}
      type="text"
      id={id}
      name={name}
      disabled={disabled}
      autoFocus={autoFocus}
      defaultValue={isControlled ? undefined : defaultValue || ""}
      value={isControlled ? value || "" : undefined}
      onChange={(event) => {
        onChange?.(props.type === "email" ? event.target.value.trim() : event.target.value);
      }}
      onBlur={onBlur}
      className={cn(inputVariants({ size, disabled }), className)}
      {...inputProps}
    />
  );
});

TextInput.displayName = "TextInput";

export { TextInput };
